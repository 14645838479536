import React, { useContext } from "react"
import { graphql } from "gatsby"
import { AuthContext } from "../context/auth"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import "../css/background-image.css"
import { Grid, Hidden } from "@material-ui/core"
import { injectIntl } from "gatsby-plugin-intl"
import RedesSociales from "../components/RedesSociales"
import { convertToBgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Botones from "../components/Botones"
import BotonesMovil from "../components/BotonesMovil"
import Carteles from "../components/Carteles"
import CartelesMovil from "../components/CartelesMovil"
import Videos from "../components/Videos"
import RedesSocialesMovil from "../components/RedesSocialesMovil"
import VolverLogin from "../components/VolverLogin"

const Lobby = ({ intl, data }) => {
  const { user } = useContext(AuthContext)
  const currentLocale = intl.locale

  const {
    nombre,
    imagen_fondo,
    redes,
    carteles,
    lobby,
    redes_width,
    redes_left,
    redes_bottom,
    redes_color_fondo,
    redes_mostrar,
  } = data.strapiLobby

  const image = getImage(imagen_fondo.localFile)
  const bgImage = convertToBgImage(image)

  return (
    <>
      {user != null ? (
        <Layout>
          <SEO
            title={
              currentLocale === "en"
                ? nombre.en
                : currentLocale === "pt"
                ? nombre.pt
                : nombre.es
            }
          />
          <Hidden xsDown>
            <BackgroundImage
              Tag="section"
              {...bgImage}
              preserveStackingContext
              style={{ position: "fixed", top: 0 }}
              className="backimage"
            >
              {carteles.map(cartel => (
                <div
                  key={cartel.id}
                  style={{
                    position: "absolute",
                    left: cartel.left,
                    top: cartel.top,
                    width: cartel.width,
                  }}
                >
                  <Carteles cartel={cartel} />
                </div>
              ))}
              {lobby.map(items => (
                <div key={items.id}>
                  {items.strapi_component === "botones.boton" ? (
                    <div
                      style={{
                        position: "absolute",
                        left: items.left,
                        top: items.top,
                        width: items.width,
                      }}
                    >
                      <Botones boton={items} />
                    </div>
                  ) : items.strapi_component === "videos.video" ? (
                    <div
                      style={{
                        position: "absolute",
                        left: items.left,
                        top: items.top,
                        width: items.width,
                        height: items.height,
                      }}
                    >
                      <Videos video={items} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}

              {redes_mostrar ? (
                <div
                  className="redesLobby2"
                  style={{
                    width: redes_width,
                    position: "absolute",
                    left: redes_left,
                    bottom: redes_bottom,
                    background: `${redes_color_fondo}`,
                  }}
                >
                  <RedesSociales redes={redes} />
                </div>
              ) : (
                ""
              )}
            </BackgroundImage>
          </Hidden>

          {/*
INICIO DE VERSION PARA CELULAR
*/}
          <Hidden smUp>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                //  backgroundImage: `url(${fondoweb})`,
                backgroundColor: `${data.configuracion.color_movil}`,
                //     overflow: "auto",
                //height: "100%",
                //     overflowX: "hidden",
                //    width: "100%"
              }}
            >
              {data.configuracion.logo_movil !== null &&
              data.configuracion.logo_movil.localFile !== null ? (
                <GatsbyImage
                  image={
                    data.configuracion.logo_movil.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt="cabecera"
                />
              ) : (
                ""
              )}
              {/*               <div>&nbsp;</div>
              {imagen_fondo !== null && imagen_fondo.localFile !== null ? (
                <GatsbyImage
                  image={imagen_fondo.localFile.childImageSharp.gatsbyImageData}
                  alt="ambiente"
                />
              ) : (
                ""
              )} */}

              <div>&nbsp;</div>
              {lobby.map(items => (
                <div key={items.id}>
                  {items.strapi_component === "botones.boton" ? (
                    <Grid item xs={12} style={{ marginTop: 20, width: "100%" }}>
                      <BotonesMovil boton={items} />
                    </Grid>
                  ) : items.strapi_component === "videos.video" ? (
                    <Grid item xs={12}>
                      <Videos video={items} />
                    </Grid>
                  ) : (
                    ""
                  )}
                </div>
              ))}
              <div>&nbsp;</div>
              {carteles.map(cartel => (
                <Grid item xs={12} key={cartel.id}>
                  <CartelesMovil cartel={cartel} />
                </Grid>
              ))}
              <div>&nbsp;</div>

              <Grid item xs={12}>
                <Grid
                  container
                  justify="center"
                  //   direction="row"
                  //  style={{ textAlign: "center" }}
                >
                  <RedesSocialesMovil redes={redes} />
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Layout>
      ) : (
        <VolverLogin />
      )}
    </>
  )
}

export default injectIntl(Lobby)

export const query = graphql`
  query {
    configuracion: strapiConfiguracion {
      color_movil
      logo_movil {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    strapiLobby {
      nombre {
        es
        en
        pt
      }
      imagen_fondo {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      redes_width
      redes_left
      redes_bottom
      redes_color_fondo
      redes_mostrar
      redes {
        audio
        carro_compras
        contenido
        correo
        descargas
        facebook
        instagram
        linkedin
        skype
        telefono
        telegram
        twitter
        web
        web_externa
        whatsapp
        youtube
        zoom
      }
      carteles {
        ampliar_cartel
        mostrar_celular
        mostrar
        nombre
        proporcion
        left
        id
        tiempo_slideshow
        top
        width
        marco_size
        color_fondo_marco
        slideshow_imagenes {
          id
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          imagen_link
          imagen_link_tipo
        }
      }
      lobby
    }
  }
`
